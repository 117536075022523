import { Settings } from '../app/shared/models/settings';

// The list of which env maps to which file can be found in `.angular-cli.json`.
const settings: Settings = {
  apiProtocol: 'https',
  apiHost: 'api.afaqy.sa',
  jsonHost: 'http://localhost:4200',
  stompConfig: {
    url: 'https://stageavl.afaqy.sa:12163/socket',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    heartbeat_in: 0,
    heartbeat_out: 50000,
    reconnect_delay: 0,
    debug: true,
  },
  pagination: {
    limit: 1000,
  },
  // apiPort:3000,
  WSStompUnitsURL: 'https://avlservice-p2.afaqy.sa:12171/socket',
  AVL_Login_URL: 'https://afaqy.pro/auth/login',
  intervalGap: 15,
  signalIntervalTimeout: 60,
};
export const environment = {
  production: false,
  settings,
  localToken:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5hZmFxeS5zYS9hdXRoL2xvZ2luX2FzIiwiaWF0IjoxNzAyOTg2NzAyLCJleHAiOjE3MDU1Nzg3MDIsIm5iZiI6MTcwMjk4NjcwMiwianRpIjoiVUEyeTY5VjJhaGh0QjJSYyIsInN1YiI6IjViNTQ3NWVlZWM2ODlkMzBjNTI3ZjEyNCIsInBydiI6IjI3MGVjZmM3ZWEzZWQ5MzdlYTg0OTM2MmEzYTUwOTEwYzZkOGNlNGYiLCJsb2dpbl9hc19pZCI6IjYzOTU5ZWMwMjUzYzM5MWU5MzM4YTdhMCJ9.80hHFgw43vj6iNMytAIn2Zu7jnbCmtW4L-RGWM9RmfU',
};
